import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacyPolicy = () => {
    useEffect(() => {
        if (window.AdobeDC) {
            loadPDF();
            return;
        }
        const script = document.createElement('script');
        script.src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';
        script.onload = () => {
          document.addEventListener('adobe_dc_view_sdk.ready', () => {
              loadPDF();
          });
        };
        document.body.appendChild(script);
      }, []);

      function loadPDF() {
            const adobeDCView = new window.AdobeDC.View({ clientId: '226b2d3d0f724531b53f027ce5331f76', divId: 'adobe-dc-view' });
            //226b2d3d0f724531b53f027ce5331f76
            //852931961acf45718f8cd1dd7651e08e
            adobeDCView.previewFile(
              {
                content: { location: { url: 'https://businessunitresources.blob.core.windows.net/public-privacy-resources/PrivacyNotice_SpeedyDock.pdf' } },
                metaData: { fileName: 'privacy-policy.pdf' }
		      }, {embedMode: "IN_LINE", showDownloadPDF: false, showPrintPDF: false}
            );
      }

    return (
        <Layout>
            <Helmet>
                <script src="https://acrobatservices.adobe.com/view-sdk/viewer.js" async></script>
            </Helmet>
            <SEO title="Privacy Policy" description="Privacy policy for SpeedyDock marina software, website, and services." />
            <Box pb={8} pt={8}>
                <Container maxWidth="lg">
                    <div id="adobe-dc-view" style={{ width: '100%' }}></div>
                </Container>
            </Box>
            <style>{`.body-container-wrapper { min-height:auto !important; }`}</style>
        </Layout>
    );
};

export default PrivacyPolicy;
